<template>
   <div>
        <div class="lg:hidden sticky-button" @click="showModal()">
          <p class="text-white text-xl" v-if="selectedChoice == 'patient'">Apply Now</p>
          <p class="text-white text-xl" v-if="selectedChoice == 'business'">Enquire Now</p>
        </div>
        <vs-popup
          class="overflow-hidden"
          title="TLC Loan application"
          :active.sync="modal"
        >
          <div class="w-full" v-if="selectedChoice == 'patient'">
            <div class="mt-10">
                <vs-card class="p-3">
                    <p class="font-bold text-lg">Get Started</p>
                    <p class="text-base">Input the email of the participating person below to receive an application form.</p>
    				<div class="flex flex-col lg:flex-row">
    					<div class="w-full lg:w-9/12">
    						<vs-select
    							v-model="selectedClinic"
    							placeholder="Select clinic"
    							autocomplete
    							class="mr-6 mt-3 mb-3 w-full"
    							v-validate="'required'"
    						>
    							<vs-select-item
    							:key="index"
    							:value="item.clinicId"
    							:text="item.name"
    							v-for="(item, index) in clinics"
    							/>
    						</vs-select>
    						<vs-input v-model="patientEmail" class="w-full lg:mb-0 mb-2" placeholder="mitch.w@freshclinics.com.au"/>
    					</div>
    					<div class="w-full lg:w-3/12 flex content-end lg:pl-3">
    						 <vs-button class="w-full self-end" @click="sendEmail">Send</vs-button>
    					</div>
                    </div>
                </vs-card>
            </div>
          </div>

          <div class="w-full items-center flex flex-col" v-if="selectedChoice == 'business'">
    				<div class="flex flex-col" v-if="partnerId == null">
    					<div class="w-full ">
    						<vs-select
    							v-model="selectedClinic"
    							placeholder="Select clinic"
    							autocomplete
    							class="mr-6 mt-3 mb-3 w-full"
    							v-validate="'required'"
    						>
    							<vs-select-item
    							:key="index"
    							:value="item.clinicId"
    							:text="item.name"
    							v-for="(item, index) in clinics"
    							/>
    						</vs-select>
    					</div>
    					<div class="w-full flex content-end lg:pl-3">
    						 <vs-button class="w-full self-end" @click="clinicDetails()">Choose</vs-button>
    					</div>
            </div>
 
            <iframe v-if="partnerId != null" :src="getIframeSource()" height="500" />
          </div>
        </vs-popup>
 

        <div class="lg:w-1/2">
        <p class="text-3xl font-bold">
        Power your business
        <span class="text-xl font-bold" style="color:#EF4D97">TLC</span>
        with loans
        </p>

        </div>
        <div class="mt-10">
          <p>Select your loan type</p>
          <div class="mt-2 flex">
            <vs-button class="mr-2 rounded-full" type="border" @click="setChoice('patient')">Patient Loans</vs-button>
            <vs-button class="rounded-full" type="border" @click="setChoice('business')">Business Loans</vs-button>
          </div>
        </div>
        <div class="mt-10" v-if="selectedChoice == 'patient'">
          <p class="font-bold text-xl">TLC patient loans help fund expensive treatments for your patients.</p>
          <p class="text-base mt-5">Simply fill out the form and recieve payment upfront for your treatments!</p>
        </div>
        <div class="mt-10" v-if="selectedChoice == 'business'">
          <p class="font-bold text-xl">Grow your business with TLC commercial loans.</p>
          <p class="text-base">Give your clinic a face lift</p>
          <p class="text-base">Buy expensive money making machines</p>
          <p class="text-base">+ more!</p>
        </div>

        <vs-button class="mt-5 hidden md:flex" v-if="selectedChoice == 'patient'" @click="showModal()">Apply Now</vs-button>
        <vs-button class="mt-5 hidden md:flex" v-if="selectedChoice == 'business'" @click="showModal()">Enquire Now</vs-button>
 
        <div class="mt-20">
            <p class="font-bold text-2xl">How it works</p>
            <div class="mt-10 lg:flex justify-center">
                <div class="lg:w-1/3 px-20 lg:px-0 lg:mb-0 mb-10">
                    <div class="text-center">
                        <feather-icon
                        icon="ClockIcon"
                        svgClasses="h-12 w-12"
                        class="ml-1"
                        />
                        <p class="text-lg font-bold">
                            Apply in 5 minutes
                        </p>
                        <p class="text-base">
                            Fill out the application form you receive in your inbox
                        </p>
                    </div>
                </div>
                <div class="lg:w-1/3  px-20 lg:px-0 lg:mb-0 mb-10">
                    <div class="text-center">
                        <feather-icon
                        icon="MessageSquareIcon"
                        svgClasses="h-12 w-12"
                        class="ml-1"
                        />
                        <p class="text-lg font-bold">
                            TLC will review
                        </p>
                        <p class="text-base">
                            TLC will review and process your application
                        </p>
                    </div>
                </div>
                <div class="lg:w-1/3  px-20 lg:px-0">
                    <div class="text-center">
                        <feather-icon
                        icon="AwardIcon"
                        svgClasses="h-12 w-12"
                        class="ml-1"
                        />
                        <p class="text-lg font-bold">
                            Settle & get paid
                        </p>
                        <p class="text-base">
                            Funds will be paid into the nominated account
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
          modal: false,
			clinics : [],
			organizationId : null,
			patientEmail: "",
			selectedClinic : null,
      selectedChoice: 'patient',
      choicePatient: 'border',
      choiceBusiness: 'border',
      partnerId: 0
        }
    },
    methods: {
        ...mapActions("tlc", ["sendTlcEmail","getClinicDetails"]),
		...mapActions("organization", ["fetchOrganizationAllClinics"]),
		async getOrganizationAllClinicsList() {
			const res = await this.fetchOrganizationAllClinics(this.organizationId);
			if(res.data) {
				this.clinics = [
					...res.data.data,
				];
        console.log('res',res)
			}
		},
    getIframeSource() {
        return "https://dev.tlc.com.au/commercial/enquiry?partner_id="+this.partnerId
      },
    async clinicDetails() {
        const res = await this.getClinicDetails({
            payload: {
                clinicId: this.selectedClinic
              }
          })
        if (res.data.data.tlcPartnerId) {
            this.partnerId = res.data.data.tlcPartnerId
          }
      },
    showModal() {
        this.modal = true
        this.partnerId = null
      },
    setChoice(type) {
      this.selectedChoice = type
      /**if (type === 'patient'){
        this.choicePatient = 'filled'
        this.choiceBusiness = 'border'
      }
       if (type === 'business'){
        this.choiceBusiness = 'filled'
        this.choicePatient = 'border'
      }  **/      
    },
    sendEmail() {
			if (!this.selectedClinic) {
                this.$vs.notify({
                    title: "Failed.",
                    text: "Please select a clinic.",
                    color: "danger",
                });
                return
            }
            if (!this.patientEmail) {
                this.$vs.notify({
                    title: "Failed.",
                    text: "Please input email.",
                    color: "danger",
                });
                return
            }
            this.$vs.loading();
            this.sendTlcEmail({ payload: {
                patientEmail: this.patientEmail,
				clinicId: this.selectedClinic
            }})
            .then((res)=>{
                this.$vs.loading.close()
                this.$vs.notify({
                    title: res.data.title,
                    text: res.data.message,
                    color: "success",
                });
				this.patientEmail = "";
            })
            .catch((err)=>{
                this.$vs.loading.close()
                this.$vs.notify({
                    title: err.data.title,
                    text: err.data.message,
                    color: "danger",
                });
            })
        }
    },
	async created() {
		this.organizationId = localStorage.getItem(
		`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
		);
		this.getOrganizationAllClinicsList();
	}
}
</script>
<style scoped>
.sticky-button {
    background-color: #3CC9B2;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    position: fixed;
    left: 0;
    height: 70px;
    opacity: 1;
    justify-content: center;
    align-items: center;
    display: flex;
  }
.sticky-button:hover {
    opacity: 0.7;
  }
</style>
