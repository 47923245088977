<template>
    <div>
        <vs-card>
        <vs-tabs class="compliance-list" id="compliance-id">
        <vs-tab label="Apply Now" id="compliance-up-next">
            <financialAssistanceApply/>
        </vs-tab>
        <vs-tab label="Applications" id="compliance-overdue">
			<financialList />
        </vs-tab>
      </vs-tabs>
      </vs-card>
    </div>
</template>


<script>
import financialAssistanceApply from "./FinancialAssistanceApply.vue";
import financialList from "./FinancialList.vue";

export default {
  components: {
    financialAssistanceApply,
	financialList
  },
  data: () => ({}),
};
</script>
