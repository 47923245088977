<template>
   <div>
        <div>
			<div class="md:mt-3">
				<div class="hidden md:flex flex-row bg-table-header border-solid border-table-border border-b border-r-0 border-l-0">
					<div class="text-left px-3 py-3 flex-1" v-for="heading in ['APPLICANTS NAME','VALUE','STARTED','STATUS','CLINIC']" :key="heading">{{ heading }}</div>
				</div>
				<div class="flex flex-col md:flex-row border-solid border-table-border border-b border-r-0 border-l-0 border-t-0 first:border-t-1" v-for="(application, _id) in applications" :key="_id">
					<div class="py-1 px-3 md:py-3 flex-1">
						<span class="flex md:hidden font-bold">APPLICANTS NAME</span>
						<span>{{ application.firstName + ' ' + application.lastName }}</span>
					</div>
					<div class="py-1 px-3 md:py-3 flex-1">
						<span class="flex md:hidden font-bold">VALUE</span>
						<span>$0.00</span>
					</div>
					<div class="py-1 px-3 md:py-3 flex-1">
						<span class="flex md:hidden font-bold">STARTED</span>
						<span>{{ formatDate(application.updatedAt) }}</span>
					</div>
					<div class="py-1 px-3 md:py-3 flex-1">
						<span class="flex md:hidden font-bold">STATUS</span>
						<span class="rounded-full py-1 px-3"
						:class="renderTag(application.status)"
						>{{ renderStatus(application.status) }}</span>
					</div>
					<div class="py-1 px-3 md:py-3 flex-1">
						<span class="flex md:hidden font-bold">CLINIC</span>
						<span>{{ application.clinicId.clinicName }}</span>
					</div>
				</div>
			</div>
        </div>
    </div> 
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
    data() {
        return {
			organizationId : null,
			applications : []
        }
    },
    methods: {
        ...mapActions("tlc", ["sendTlcEmail", "getTLCapplications"]),
		async init () {
			// Fetch all applications under the organization
			const {data} = await this.getTLCapplications(this.organizationId);
			this.applications = data.data;
		},
		formatDate(date) {
			return moment(date).format('DD MMM YYYY');
		},
		renderTag (status) {
			console.log(status)
			let color = "";
			switch(status){
				case 'new':
				case 'taken':
				case 'approved':
				case 'cond. approved':
					color = "bg-tag-processing text-tag-processing-text";
					break;
				case 'rejected':
					color = "bg-tag-cancelled text-tag-cancelled-text";
					break;
				case 'accepted':
				case 'settled':
					color = "bg-tag-success text-tag-success-text";
					break;
				default:
					break;
			}
			console.log(color)
			return color;
		},
		renderStatus (rawStatus) {
			let status = "";
			switch(rawStatus){
				case 'new':
				case 'taken':
				case 'approved':
				case 'cond. approved':
					status = "Processing";
					break;
				case 'rejected':
					status = "Cancelled";
					break;
				case 'accepted':
				case 'settled':
					status = "Paid";
					break;
				default:
					break;
			}
			return status;
		}
    },
	async created() {
		this.organizationId = localStorage.getItem(
			`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
		);
		this.init();
	}
}
</script>